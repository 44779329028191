import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Dbms({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: { fill: "var(--primary)" },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        strokeWidth="10"
        d="M249.729 5C130.035 5 33 51.4142 33 108.663V391.355C33 448.586 130.017 495 249.729 495C369.406 495 466.458 448.586 466.458 391.355V108.663C466.458 51.4142 369.406 5 249.729 5ZM249.729 23.8414C366.33 23.8414 447.617 68.5411 447.617 108.663C447.617 148.767 366.348 193.467 249.729 193.467C133.11 193.467 51.8414 148.767 51.8414 108.663C51.8414 68.5411 133.093 23.8414 249.729 23.8414ZM447.617 372.496V391.337C447.617 431.441 366.348 476.141 249.729 476.141C133.11 476.141 51.8414 431.441 51.8414 391.337V339.355C85.6711 375.518 161.514 400.74 249.729 400.74C337.927 400.74 413.787 375.518 447.617 339.355V372.496ZM447.617 297.112C447.617 337.217 366.348 381.916 249.729 381.916C133.11 381.916 51.8414 337.217 51.8414 297.112V245.131C85.6711 281.293 161.514 306.515 249.729 306.515C337.927 306.515 413.787 281.293 447.617 245.131V297.112ZM447.617 202.888C447.617 242.992 366.348 287.692 249.729 287.692C133.11 287.692 51.8414 242.992 51.8414 202.888V150.906C85.6711 187.069 161.514 212.291 249.729 212.291C337.927 212.291 413.787 187.069 447.617 150.906V202.888Z"
      />
    </motion.svg>
  );
}
