import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Serverless({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        d="M104.15 369.775C107.608 366.318 112.298 364.375 117.188 364.375H132.812C137.702 364.375 142.392 366.318 145.85 369.775C149.307 373.233 151.25 377.923 151.25 382.812C151.25 387.702 149.307 392.392 145.85 395.85C142.392 399.307 137.702 401.25 132.812 401.25H117.188C112.298 401.25 107.608 399.307 104.15 395.85C100.693 392.392 98.75 387.702 98.75 382.812C98.75 377.923 100.693 373.233 104.15 369.775Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M11.3833 42.1625L12.8583 39.0871L10.5331 36.5917C7.27626 33.0966 5.50323 28.4738 5.5875 23.6972C5.67178 18.9206 7.60678 14.3633 10.9849 10.9852C14.3629 7.60711 18.9203 5.67211 23.6969 5.58783C28.4458 5.50405 33.0427 7.25612 36.5306 10.477L489.524 463.47C492.744 466.958 494.496 471.555 494.413 476.303C494.328 481.08 492.393 485.637 489.015 489.015C485.637 492.394 481.08 494.329 476.303 494.413C471.527 494.497 466.904 492.724 463.409 489.467L460.913 487.142L457.838 488.617C449.309 492.707 439.765 495 429.688 495H70.3125C52.9906 495 36.3781 488.119 24.1296 475.871C11.8811 463.622 5 447.01 5 429.688V351.563C5 334.241 11.8811 317.628 24.1296 305.38C36.3781 293.131 52.9906 286.25 70.3125 286.25H248.125H260.196L251.661 277.715L189.161 215.215L187.696 213.75H185.625H70.3125C52.9906 213.75 36.3781 206.869 24.1296 194.621C11.8811 182.372 5 165.76 5 148.438V70.3128C5 60.2355 7.29287 50.6915 11.3833 42.1625ZM298.536 324.59L297.071 323.125H295H70.3125C62.7704 323.125 55.5372 326.121 50.2042 331.454C44.8711 336.788 41.875 344.021 41.875 351.563V429.688C41.875 437.23 44.8711 444.463 50.2042 449.796C55.5372 455.129 62.7704 458.125 70.3125 458.125H420H432.071L423.536 449.59L298.536 324.59ZM138.75 176.875H150.821L142.286 168.34L50.4105 76.4648L41.875 67.9293V80.0003V148.438C41.875 164.137 54.6136 176.875 70.3125 176.875H138.75Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M156.25 5H429.688C447.009 5 463.622 11.8811 475.87 24.1296C488.119 36.3781 495 52.9906 495 70.3125V148.438C495 165.759 488.119 182.372 475.87 194.62C463.622 206.869 447.009 213.75 429.688 213.75H328.125C323.235 213.75 318.545 211.807 315.088 208.35C311.63 204.892 309.688 200.202 309.688 195.312C309.688 190.423 311.63 185.733 315.088 182.275C318.545 178.818 323.235 176.875 328.125 176.875H429.688C437.23 176.875 444.463 173.879 449.796 168.546C455.129 163.213 458.125 155.98 458.125 148.438V70.3125C458.125 62.7704 455.129 55.5372 449.796 50.2042C444.463 44.8711 437.23 41.875 429.688 41.875H156.25C151.36 41.875 146.67 39.9325 143.213 36.4748C139.755 33.0171 137.812 28.3274 137.812 23.4375C137.812 18.5476 139.755 13.8579 143.213 10.4002C146.67 6.94252 151.36 5 156.25 5ZM411.25 304.688C411.25 299.798 413.193 295.108 416.65 291.65C420.108 288.193 424.798 286.25 429.688 286.25C447.009 286.25 463.622 293.131 475.87 305.38C488.119 317.628 495 334.241 495 351.562C495 356.452 493.057 361.142 489.6 364.6C486.142 368.057 481.452 370 476.562 370C471.673 370 466.983 368.057 463.525 364.6C460.068 361.142 458.125 356.452 458.125 351.562C458.125 344.02 455.129 336.787 449.796 331.454C444.463 326.121 437.23 323.125 429.688 323.125C424.798 323.125 420.108 321.182 416.65 317.725C413.193 314.267 411.25 309.577 411.25 304.688Z"
        variants={iconPath}
        strokeWidth="5"
      />
    </motion.svg>
  );
}
