import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function TicTacToe({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        strokeWidth="4"
        d="M151.234 10V489.562M10 348.127H489.434M344.877 10V489.562M10 154.484H487.335M216.622 216.658L282.922 282.958M217.48 282.082L282.046 217.516M408.056 20.6058L474.356 86.9059M408.914 86.0297L473.48 21.4638M22.9789 412.656L89.279 478.956M23.8551 478.098L88.421 413.532M146.379 10H155.908V489.562H146.379V10ZM340.77 10H349.35V489.58H340.77V10ZM10 341.756H489.434V348.108H10V341.756ZM10 151.307H489.434V157.66H10V151.307ZM476.309 83.9669L471.618 88.6948L406.066 23.5083L410.758 18.7804L476.309 83.9669ZM410.612 88.567L405.938 83.8209L471.8 18.9264L476.473 23.6726L410.612 88.567ZM284.784 279.307L280.092 284.035L214.541 218.831L219.232 214.103L284.784 279.307ZM219.068 283.926L214.395 279.18L280.257 214.285L284.93 219.031L219.068 283.926ZM91.2687 476.017L86.5773 480.745L21.0257 415.54L25.7171 410.812L91.2687 476.017ZM25.5345 480.635L20.8614 475.889L86.7233 410.995L91.3965 415.741L25.5345 480.635ZM56.1289 10C30.6458 10 10 29.587 10 53.7559C10 77.9247 30.6458 97.5117 56.1289 97.5117C81.6121 97.5117 102.258 77.9247 102.258 53.7559C102.258 29.587 81.6121 10 56.1289 10ZM56.1289 90.8671C34.5157 90.8671 16.9914 74.2556 16.9914 53.7376C16.9914 33.2196 34.5157 16.6081 56.1289 16.6081C77.7422 16.6081 95.2664 33.2196 95.2664 53.7376C95.2664 74.2556 77.7422 90.8671 56.1289 90.8671ZM441.206 402.488C415.723 402.488 395.077 422.075 395.077 446.244C395.077 470.413 415.723 490 441.206 490C466.689 490 487.335 470.413 487.335 446.244C487.335 422.075 466.689 402.488 441.206 402.488ZM441.206 483.374C419.593 483.374 402.068 466.762 402.068 446.244C402.068 425.726 419.593 409.115 441.206 409.115C462.819 409.115 480.343 425.726 480.343 446.244C480.343 466.762 462.819 483.374 441.206 483.374Z"
      />
    </motion.svg>
  );
}
