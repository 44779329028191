import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Meta from "../components/Meta";
import HeroSection from "../components/HeroSection";
import * as styles from "../styles/services.module.css";
import * as heroStyles from "../styles/hero-section.module.css";
import { motion } from "framer-motion";
import Card from "../components/Card";
import Snowflake from "../components/icons/Snowflake";
import Aws from "../components/icons/Aws";
import OnPrem from "../components/icons/OnPrem";
import Dbms from "../components/icons/Dbms";
import Microservices from "../components/icons/Microservices";
import Serverless from "../components/icons/Serverless";
import TicTacToe from "../components/icons/TicTacToe";
import Wrench from "../components/icons/Wrench";
import Build from "../components/icons/Build";
import Manage from "../components/icons/Manage";
import LinearReveal from "../components/animation/LinearReveal";

export default function DataEngineering() {
  const technologyRow = [
    {
      icon: <Snowflake />,
      title: "Snowflake",
    },
    {
      icon: <Aws />,
      title: "Big data in AWS/Azure",
    },
    {
      icon: <OnPrem />,
      title: "Cloud NO SQL technologies",
    },
    {
      icon: <Dbms />,
      title: "RDBMS",
    },
    {
      icon: <Microservices />,
      title: "Microservices Architecture",
    },
    {
      icon: <Serverless />,
      title: "Serverless",
    },
  ];
  const dataEngR1 = [
    {
      title: "Stratergy",
      icon: <TicTacToe />,
      content: (
        <div className="content">
          <ul>
            <li>
              Enterprise Data Warehouse (EDW) to Enterprise Data Lake (EDL) Migration
            </li>
            <li>MDM /Data Governance</li>
            <li>Data Engineering Architecture</li>
            <li>Data Science Workshops</li>
          </ul>
        </div>
      ),
    },
    {
      title: "Manage",
      icon: <Manage />,
      content: (
        <div className="content">
          <ul>
            <li>Managed Data services</li>
            <li>Data Science Model Development Support</li>
            <li>Data Lifecycle Automation</li>
            <li>Continuous Improvement Initiatives</li>
          </ul>
        </div>
      ),
    },
  ];
  const dataEngR2 = [
    {
      title: "Design",
      icon: <Wrench />,
      content: (
        <div className="content">
          <ul>
            <li>Data Visualization, Dashboards, and Reporting</li>
            <li>Enterprise Data Lake</li>
            <li>ETL and Data Processing Pipelines</li>
            <li>Data Warehousing Migration</li>
            <li>AI/ML Modeling</li>
            <li>Data Lake Optimization</li>
            <li>Data Integration Strategy</li>
            <li>Data Collaboration and Sharing</li>
          </ul>
        </div>
      ),
    },
    {
      title: "Build",
      icon: <Build />,
      content: (
        <div className="content">
          <ul>
            <li>Data Lake Development</li>
            <li>ETL and Data Pipeline Development (Batch and Streaming)</li>
            <li>Dashboards / Reports / Data Visualization Development</li>
            <li>Master Data Management</li>
            <li>Data Harmonization / Data Governance implementation</li>
            <li>Manual/Automated Data Cleansing/Conversion</li>
            <li>AI/ML Model Development</li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <Meta
        title="Data Engineering - DBT"
        description="Drive real-time Data-Driven insights through efficient Data Engineering"
      />
      <HeroSection
        title="Data Engineering"
        titleClass={"text-primary " + heroStyles.heroTitle}
        description="Drive real-time Data-Driven insights through efficient Data Engineering"
        descClass={"has-text-white-bis " + heroStyles.heroDesc}
        imageClass={heroStyles.dataEngineeringImage + " " + heroStyles.heroData}
        divClass={heroStyles.divClass}
      />
      <section>
        <LinearReveal>
          <h1 className={"text-primary has-text-centered " + styles.heading}>
            Gain a Competitive Advantage
          </h1>{" "}
        </LinearReveal>{" "}
        <LinearReveal>
          <div className="px-5" style={{ maxWidth: "1000px", margin: "auto" }}>
            <p className={styles.description}>
              DBT's data engineering team specializes in reducing the pain points in data
              engineering with proven capabilities to ingest, transform, and deliver your
              data for faster, deeper insights.
            </p>
          </div>
        </LinearReveal>
        <LinearReveal>
          <h2
            className={
              "sub-title text-heading has-text-centered " + styles.sectionHeading
            }
          >
            Our Technology Expertise
          </h2>
        </LinearReveal>{" "}
        <LinearReveal>
          <div className="is-flex is-justify-content-center is-flex-wrap-wrap mx-auto">
            {technologyRow.map((card, index) => (
              <div key={index} className="is-flex is-justify-content-center m-4">
                <Card
                  icon={card.icon}
                  title={card.title}
                  cardStyle={{ width: "300px", paddingTop: "30px" }}
                  titleClass={"text-heading pb-0 mb-0 " + styles.dataEngCardTitle}
                  cardContentClass="mb-2 pb-0"
                />
              </div>
            ))}
          </div>{" "}
        </LinearReveal>
        <LinearReveal>
          <h2
            className={
              "sub-title text-heading has-text-centered " + styles.sectionHeading
            }
          >
            Our Data Engineering Services
          </h2>
        </LinearReveal>
        <LinearReveal>
          <div className="is-flex is-justify-content-space-evenly is-flex-wrap-wrap mx-auto">
            {dataEngR1.map((card, index) => (
              <div key={index} className="is-flex is-justify-content-center m-4">
                <Card
                  icon={card.icon}
                  title={card.title}
                  titleClass={"text-heading mb-0 " + styles.dataEngCardTitle}
                  cardStyle={{ width: "400px", paddingTop: "30px" }}
                  maxWidth="400px"
                  content={card.content}
                  contentClass="text-description"
                />
              </div>
            ))}{" "}
          </div>{" "}
        </LinearReveal>
        <LinearReveal>
          <div className="is-flex is-justify-content-space-evenly is-flex-wrap-wrap mx-auto">
            {dataEngR2.map((card, index) => (
              <div key={index} className="is-flex is-justify-content-center m-4">
                <Card
                  icon={card.icon}
                  title={card.title}
                  titleClass={"text-heading mb-0 " + styles.dataEngCardTitle}
                  cardStyle={{ width: "400px", paddingTop: "30px" }}
                  maxWidth="400px"
                  content={card.content}
                  contentClass="text-description"
                />
              </div>
            ))}{" "}
          </div>{" "}
        </LinearReveal>
        <LinearReveal>
          <h2
            className={
              "sub-title text-heading has-text-centered " + styles.sectionHeading
            }
          >
            Maximize Value from your Cloud Data
          </h2>
          <div className="is-flex is-justify-content-center">
            <Link to="/contact-us">
              <motion.button
                className="button px-4"
                style={{ transitionDuration: "0s" }}
                initial={{ border: "2px solid var(--secondary)", color: "#4a4a4a" }}
                whileHover={{
                  scale: 1.1,
                  backgroundColor: "var(--secondary)",
                  color: "#fff",
                }}
              >
                Contact Us
              </motion.button>
            </Link>
          </div>
        </LinearReveal>
      </section>
    </Layout>
  );
}
