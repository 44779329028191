import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Wrench({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        strokeWidth="20"
        d="M335.02 36.6129L335.025 36.6075L335.031 36.6021C346.04 25.5715 359.707 17.5668 374.714 13.3614C383.547 10.8858 392.669 9.77609 401.766 10.0374L347.173 64.6039L343.088 68.687L344.583 74.2657L360.241 132.678L361.735 138.254L367.311 139.748L425.698 155.397L431.275 156.892L435.357 152.81L489.933 98.2477C490.597 122.32 481.758 146.587 463.396 164.948L463.394 164.951C452.375 175.977 438.701 183.978 423.69 188.184C408.68 192.389 392.839 192.657 377.695 188.961L372.23 187.627L368.253 191.606L191.598 368.293L187.623 372.269L188.955 377.732C192.646 392.869 192.375 408.702 188.168 423.704C183.962 438.705 175.961 452.371 164.938 463.382L164.929 463.391C153.917 474.419 140.249 482.424 125.242 486.632C116.412 489.108 107.293 490.221 98.1985 489.964L152.786 435.39L156.87 431.307L155.375 425.728L139.717 367.324L138.223 361.749L132.647 360.255L74.2597 344.606L68.6834 343.111L64.6007 347.193L10.0335 401.747C9.36948 377.675 18.2086 353.407 36.57 335.046L36.5722 335.044C47.5915 324.018 61.2655 316.016 76.276 311.811C91.2864 307.605 107.127 307.338 122.271 311.033L127.737 312.367L131.714 308.389L308.36 131.701L312.335 127.725L311.003 122.262C307.312 107.125 307.583 91.2927 311.79 76.2909C315.996 61.2891 323.997 47.624 335.02 36.6129Z"
      />
    </motion.svg>
  );
}
