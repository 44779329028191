import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Build({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.rect
        variants={iconPath}
        x="2.5"
        y="295.09"
        width="202.41"
        height="202.41"
        strokeWidth="15"
      />
      <motion.rect
        variants={iconPath}
        x="2.5"
        y="93.0498"
        width="202.41"
        height="202.41"
        strokeWidth="15"
      />
      <motion.rect
        variants={iconPath}
        x="204.54"
        y="295.09"
        width="202.41"
        height="202.41"
        strokeWidth="15"
      />
      <motion.rect
        variants={iconPath}
        x="208.733"
        y="146.661"
        width="202.41"
        height="202.41"
        transform="rotate(-45 208.733 146.661)"
        strokeWidth="15"
      />
    </motion.svg>
  );
}
